import React from 'react'

const CompassionLanding = props => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>Compassion In Action</h1>
      </header>
      <div className="content">
        <p>
          Creating a habit of service to those in your life
          <br />
          even when it’s uncomfortable
        </p>
      </div>
    </div>
  </section>
)

export default CompassionLanding
