import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import CompassionLanding from '../components/CompassionLanding'

import pic08 from '../assets/images/elderly.jpg'
import pic09 from '../assets/images/neighbor.jpg'
import pic10 from '../assets/images/singleparent.jpg'
import pic11 from '../assets/images/hospital.jpg'
import pic12 from '../assets/images/oldcouple.jpg'
import pic13 from '../assets/images/needy.jpg'

const Landing = props => (
  <Layout>
    <Helmet>
      <title>Compassion In Action</title>
      <meta name="description" content="Compassion in action" />
    </Helmet>

    <CompassionLanding />

    <div id="main">
      {/* <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Sed amet aliquam</h2>
          </header>
          <p>
            Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
            magna sed nunc rhoncus condimentum sem. In efficitur ligula tate
            urna. Maecenas massa vel lacinia pellentesque lorem ipsum dolor.
            Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
            libero. Nullam et orci eu lorem consequat tincidunt vivamus et
            sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula
            tate urna.
          </p>
        </div>
      </section> */}
      <section id="two" className="spotlights">
        <section>
          <Link to="/resources" className="image">
            <img src={pic08} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Those You Know</h3>
              </header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1', width: '100%' }}>
                  <strong>Widows/Widowers/Elderly:</strong>
                  <ul>
                    <li>Rides to doctor appointments</li>
                    <li>Grocery shopping</li>
                    <li>Handyman projects</li>
                    <li>Yard work</li>
                    <li>Check in on them regularly for practical needs</li>
                  </ul>
                </div>
                <div style={{ flexGrow: '1', marginLeft: '35px' }}>
                  <blockquote>
                    “When a single lady at church broke her wrist, a family from
                    our church went over and did her yard while she
                    recuperated.”
                  </blockquote>
                </div>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic09} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1', width: '150%' }}>
                  <strong>Neighbors/Families:</strong>
                  <ul>
                    <li>Seasonal outdoor help</li>
                    <li>Invite them for dinner</li>
                    <li>Take a meal, unexpected, to a busy family</li>
                    <li>Babysit</li>
                  </ul>
                </div>
                <div style={{ flexGrow: '1', marginLeft: '35px' }}>
                  <blockquote>
                    "One year, 5 people from our church took one day a week and
                    took a boy to school each morning. His mom had ear/sinus
                    infection problems and could not drive. His dad was taking
                    time off work to get him to school (and losing income), so
                    for a year each person showed up at their house and drove
                    him to school"
                  </blockquote>
                </div>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic10} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1' }}>
                  <strong>Single Parents:</strong>
                  <ul>
                    <li>Offering to watch children while they shop</li>
                    <li>Take children shopping for Mother’s/Father’s Day</li>
                    <li>
                      Invite them out for girl time/ guy time with pre-arranged
                      and approved child care
                    </li>
                    <li>
                      Remember them on holidays by doing something thoughtful
                      for them
                    </li>
                  </ul>
                </div>
                <div style={{ flexGrow: '1', marginLeft: '35px' }}>
                  <strong>Families of Addicts:</strong>
                  <ul>
                    <li>Connect with prayer and encouragement</li>
                    <li>Send cards and/or emails</li>
                    <li>Invite them to your home for dinner</li>
                    <li>Go with them to join a support group</li>
                  </ul>
                </div>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic11} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Those Around You</h3>
              </header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1', marginLeft: '35px' }}>
                  <strong>
                    Long Term Hospitalized / Palliative Health Issues:
                  </strong>
                  <ul>
                    <li>Visit them and make regular contact</li>
                    <li>Invite family over for dinner</li>
                    <li>Give gift cards for restaurants near the hospital</li>
                    <li>
                      Create a gift basket with snacks, food, drinks to have in
                      the hospital room
                    </li>
                    <li>
                      Offer to stay with person who is ill for an hour, so the
                      caregiver can take a shower, or go to the store (get out
                      for an hour)
                    </li>
                  </ul>
                </div>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic12} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1' }}>
                  <strong>Elderly Neighbor:</strong>
                  <ul>
                    <li>Remember them on holidays</li>
                    <li>Yard</li>
                  </ul>
                </div>
                <div style={{ flexGrow: '1', marginLeft: '35px' }}>
                  <strong>Refugee</strong>
                  <ul>
                    <li>Invite them to your church</li>
                    <li>Teach English as a second language</li>
                    <li>
                      Get to know them. and help them learn about everyday
                      American life and your local community
                    </li>
                  </ul>
                </div>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic13} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1' }}>
                  <strong>Needy:</strong>
                  <ul>
                    <li>
                      Give out “Blessings bags” with useful items to homeless in
                      your town
                    </li>
                    <li>
                      Give comfort items (blankets, stuffed animals) to the
                      local police to have in their vehicles for children who
                      need aid.
                    </li>
                    <li>
                      Contact your local food pantry or shelter and supply
                      needed items
                    </li>
                  </ul>
                </div>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
